document.addEventListener("DOMContentLoaded", () => {
    // Shared code for "four-column-list-block" and "mosaic-with-hover-text" modules

    var carousels = $("[data-owl]");

    var rtl = document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl" ? true : false;



    //We need this to make the dot buttons accessible
    function addDotButtonText() {

        // loop through each dot element
        $('.owl-dot').each(function () {
            // remove old text nodes
            var span = $(this).find('span');

            span.empty();

            // grab its (zero-based) order in the series
            let idx = $(this).index() + 1;

            // append a span to the button containing descriptive text
            span.text("Go to slide " + idx);
        });


    }

    var carouselSettings = {
        touchDrag: true,
        mouseDrag: true,
        loop: false,
        margin: 0,
        stagePadding: 35,
        nav: false,
        rtl: rtl,
        onInitialized: function (event) {
            addDotButtonText();
        },
        onResized: function (event) {
            addDotButtonText();
        },
        responsive: {
            0: {
                items: 1
            },
            460: {
                items: 2
            }
        }
    };

    var sliderIsInitialized = false;

    function initialize() {

        //console.log("Initializing...");

        if (window.matchMedia("(min-width: 768px)").matches) {
            // destroy owl-carousel and remove all depending classes if window screensize is bigger then 767px
            carousels.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
            carousels.find('.owl-stage-outer').children().unwrap();
            //console.log("Destroyed: ", carousels.length);
            sliderIsInitialized = false;
        } else {
            // initialize owl-carousel if window screensize is less the 767px

            if (!sliderIsInitialized) {
                //console.log("Slider is not initialized yet, so do it now!");
                carousels.owlCarousel(carouselSettings);
                carousels.addClass("owl-carousel");
                sliderIsInitialized = true;
            } else {
                //console.log("Slider is already initialized");
            }
        }
    }

    if (carousels.length > 0) {
        initialize();

        $(window).resize(function () {
            initialize();
        });
    }

    var nextSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" aria-hidden="true" focusable="false"><path fill="#000" d="M.293 22.293c-.391.391-.391 1.024 0 1.414s1.024.391 1.414 0L13.414 12 1.707.293C1.316-.098.683-.098.293.293s-.391 1.024 0 1.414L10.586 12 .293 22.293z"/></svg>';
    var prevSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" aria-hidden="true" focusable="false"><path fill="#000" d="M13.7 1.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L.6 12l11.7 11.7c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L3.4 12 13.7 1.7z"/></svg>';

    function addSvgFilesToPrevAndNextButtons(event) {
        if (event.type === "initialized") {
            $(event.target).find(".owl-nav .owl-prev").append(prevSvg);
            $(event.target).find(".owl-nav .owl-next").append(nextSvg);
        }
    }




    // Code for "two-column-gallery-block"

    function twoColumnGalleryPagingCallback(event) {
        var carousel = event.relatedTarget;
        if (event.type === "initialized") {
            var pager = `<span class="paging">${carousel.relative(carousel.current()) + 1}/${carousel.items().length}</span>`;
            $(event.target).find(".owl-nav").append(pager);
            addSvgFilesToPrevAndNextButtons(event);

        } else {
            $(event.target).find(".owl-nav .paging").text(`${carousel.relative(carousel.current()) + 1}/${carousel.items().length}`);
        }
    }

    const twoColumnGallery = $("[data-owl-two-column-gallery]");

    if (twoColumnGallery.length > 0) {

        twoColumnGallery.owlCarousel({
            loop: true,
            margin: 0,
            nav: false,
            dots: true,
            items: 1,
            lazyLoad: true,
            lazyLoadEager: 1,
            animateOut: 'fadeOut',
            rtl: false,
            onInitialized: function (event) {
                twoColumnGalleryPagingCallback(event);
                addDotButtonText();
            },
            onChanged: twoColumnGalleryPagingCallback,
            onResized: function (event) {
                addDotButtonText();
            },
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2,
                    nav: true,
                    dots: false
                }
            }
        });

    }


    // Code for "four-column-gallery-block"

    const fourColumnGallery = $("[data-owl-four-column-gallery]");

    if (fourColumnGallery.length > 0) {

        fourColumnGallery.owlCarousel({
            loop: true,
            margin: 16,
            nav: false,
            dots: true,
            items: 1,
            lazyLoad: true,
            lazyLoadEager: 1,
            rtl: rtl,
            autoHeight: true,
            onInitialized: function (event) {
                addSvgFilesToPrevAndNextButtons(event);
                addDotButtonText();
            },
            onResized: function (event) {
                addDotButtonText();
            },
            responsive: {
                360: {
                    items: 2,
                    nav: false
                },
                600: {
                    items: 3,
                    nav: false
                },
                1000: {
                    items: 4,
                    nav: false
                },
                1440: {
                    items: 4,
                    nav: true,
                    dots: false
                }

            }
        });

    }


    const reviewWithQuote = $("[data-owl-review-with-quote]");

    if (reviewWithQuote.length > 0) {
        reviewWithQuote.owlCarousel({
            loop: true,
            margin: 0,
            nav: false,
            dots: true,
            items: 1,
            autoHeight: true,
            rtl: rtl,
            onInitialized: function (event) {
                addSvgFilesToPrevAndNextButtons(event);
                addDotButtonText();
            },
            onResized: function (event) {
                addDotButtonText();
            },
            responsive: {
                1100: {
                    nav: true
                }
            }
        });
    }


    // Code for "Gallery-With_Text"

    const galleryWithText = $("[data-owl-gallery-with-text]");

    function toggleTruncate(event) {
        event.preventDefault();

        const readMoreButton = $(event.target);
        const json = readMoreButton.data("dictionary");
        const container = readMoreButton.parents(".gallery-with-text");


        if (container.hasClass("is-truncated")) {
            readMoreButton.children().first().text(json.showLessText);
            container.removeClass("is-truncated");
            history.pushState("", document.title, window.location.pathname + window.location.search);
        } else {
            window.location.hash = "#" + container.attr("id");
            setTimeout(function () {
                readMoreButton.children().first().text(json.showMoreText);
                container.addClass("is-truncated");
                history.pushState("", document.title, window.location.pathname + window.location.search);
            }, 350);
        }

    }

    function truncateGalleryContent(evt) {


        const el = evt.relatedTarget.$element;
        const parent = evt.relatedTarget.$element.parent();
        const text = parent.find(".gallery-with-text__content--inner");
        const stage = el.find(".owl-stage-outer");
        const showMoreButton = parent.find(".button.read-more");
        const json = showMoreButton.data("dictionary");
        //console.log("TextHeight", text.height());
        //console.log("StageHeight", stage.height());

        if (evt.type === "initialized") {
            showMoreButton.children().first().text(json.showMoreText);
            showMoreButton.on("click", toggleTruncate);

            if (text.height() > stage.height()) {
                text.height(stage.height());
                parent.addClass('can-truncate');
                parent.addClass('is-truncated');
            }
        }

    }

    if (galleryWithText.length > 0) {

        //Notice that initialize.owl.carousel and initialized.owl.carousel events must be attached before Owl Carousel initialization.
        //This is required only for those two.
        //Source: https://owlcarousel2.github.io/OwlCarousel2/demos/events.html
        galleryWithText.on('initialized.owl.carousel', function (event) {
            truncateGalleryContent(event);
        });

        galleryWithText.owlCarousel({
            loop: true,
            margin: 0,
            nav: false,
            dots: true,
            items: 1,
            lazyLoad: true,
            lazyLoadEager: 1,
            rtl: rtl,
            onInitialized: function (event) {
                twoColumnGalleryPagingCallback(event);
                addDotButtonText();
            },
            onChanged: function (event) {
                twoColumnGalleryPagingCallback(event);
            },
            onResized: function (event) {
                addDotButtonText();
            },
            responsive: {
                768: {
                    items: 1,
                    nav: true,
                    dots: false
                }
            }
        });
    }

    // Code for "Ticket Upgrades - Used in TicketPassesList.cshtml"
    const ticketUpgrades = $("[data-owl-ticket-upgrades]");
    if (ticketUpgrades.length > 0) {

        ticketUpgrades.owlCarousel({
            loop: false,
            margin: 0,
            nav: false,
            dots: true,
            items: 1,
            autoHeight: true,
            rtl: rtl,
            onInitialized: function (event) {
                addDotButtonText();
            },
            onResized: function (event) {
                addDotButtonText();
            }
        });
    }

    // Code for "Ticket slider - Used in TicketCategoryBlock.cshtml"
    // <div class="owl-ticket-slider-wrapper"></div>

    const ticketSliders = $("[data-owl-ticket-slider=true]");
    if(ticketSliders.length > 0) {
        ticketSliders.each(function() {
            // const innerHtml = $(this).parent().html();
            // $(this).parent().html('<div class="owl-ticket-slider-wrapper">' + innerHtml + '</div>');

            $(this).owlCarousel({
                loop: false,
                stagePadding: 20,
                margin: 10,
                nav: false,
                dots: true,
                items: 1,
                // autoWidth: true,
                // autoHeight: true,
                rtl: rtl,
                responsive: {
                    360: {
                        items: 1,
                        nav: false
                    },
                    600: {
                        items: 2,
                        nav: false
                    },
                    750: {
                        items: 3,
                        nav: false
                    },
                    1440: {
                        stagePadding: 0,
                        items: 4,
                        nav: false
                    },
                    1600: {
                        stagePadding: 0,
                        items: 4,
                        nav: true
                    }

                },
                onInitialized: function (event) {
                    addSvgFilesToPrevAndNextButtons(event);
                    addDotButtonText();
                },
                onResized: function (event) {
                    addDotButtonText();
                }
            });
        })
    };

    var p;
    function setHeroFrontpageCarouselDelay(owl) {
        owl.trigger('stop.owl.autoplay');
        var carouselTiming = $('[data-owl-hero-frontpage] .owl-item.active .item').data('delay');


        if (p) {
            clearTimeout(p);
        }

        if (carouselTiming !== 0) {
            p = setTimeout(function () {
                owl.trigger('next.owl.carousel');
            }, carouselTiming);
        }


    }

    const heroFrontPageCarousel = $("[data-owl-hero-frontpage]");
    if (heroFrontPageCarousel.length > 0) {

        var initialDelay = parseInt(heroFrontPageCarousel.data("initial-delay"));

        var frontPageOwl = heroFrontPageCarousel.owlCarousel({
            autoplayTimeout: initialDelay,
            autoplay: initialDelay > 0 ? true:false,
            loop: true,
            margin: 0,
            nav: false,
            dots: true,
            items: 1,
            lazyLoad: true,
            lazyLoadEager: 1,
            autoHeight: true,
            rtl: rtl,
            onInitialized: function (event) {
                addSvgFilesToPrevAndNextButtons(event);
                addDotButtonText();
            },
            onResized: function (event) {
                addDotButtonText();
            },
            responsive: {
                1100: {
                    items: 1,
                    nav: true,
                    dots: true
                }

            }
        });


        frontPageOwl.on('initialized.owl.carousel translated.owl.carousel', function (e) {
            frontPageOwl.trigger('stop.owl.autoplay');
            setHeroFrontpageCarouselDelay(frontPageOwl);
        });

        frontPageOwl.on('translate.owl.carousel', function (e) {

            var currentSlide = $('[data-owl-hero-frontpage] .owl-item.active .item');
            var vidContainer = currentSlide.find(".inline-hero-background");
            var button = currentSlide.find(".hero-video__sound-button");
            var vid = vidContainer.find("video");
            if (vid.length) {
                vid.prop("muted", true);
                button.removeClass("on");
            }

        });
    }


    const pixleeCarousel = $("[data-owl-pixlee]");
    if (pixleeCarousel.length > 0) {

        var initialDelay = 0;

        pixleeCarousel.owlCarousel({
            autoplayTimeout: initialDelay,
            autoplay: initialDelay > 0 ? true:false,
            loop: true,
            margin: 0,
            nav: true,
            dots: false,
            items: 1,
            lazyLoad: true,
            lazyLoadEager: 1,
            autoHeight: true,
            rtl: rtl,
            onInitialized: function (event) {
                addSvgFilesToPrevAndNextButtons(event);
            },
        });
    }
});